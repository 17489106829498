import {Helmet} from "react-helmet";
import { useEffect, useState } from "react";
import logoWhite from "../static/logo-white.png";
const Blogs = ()=>{
const [loaded,setloaded] = useState(false)

  useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
      setloaded(true)
      // do something else
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);
  return (<>
    <Helmet>
      <script src="https://io.dropinblog.com/embedjs/c245ac73-387b-45ca-8f28-9299f8481078.js"></script>
    </Helmet>
    <div
      data-load={loaded}
      className="wrapper fixed top-0 left-0 z-[300] grid h-full w-full place-items-center bg-[#ACA394]"
    >
      <img alt="葵芳 中醫 針灸 正骨 拔罐 刮痧 濕疹 Covid-19 視像診症 遙距診症 高低膊 脊柱側彎 徐宇聖 鄧敏賢 聖賢堂 logo HK " src={logoWhite} className="w-[10%] min-w-[150px] md:min-w-[200px] h-auto"></img>
    </div>
    <div id="dib-posts"></div>

  </>)
}

export default Blogs

//
