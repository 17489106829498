import { useEffect } from "react";

const ScrollTop = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <>{props.children}</>;
};

export default ScrollTop;
