import React, { useEffect, lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import logoWhite from "./static/logo-white.png";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loadable from "react-loadable";
import Loading from "./components/Loading";
import ScrollTop from "./hooks/useScrollTop";
import { Provider } from "react-redux";
import store from "./store";
import BookingPage from "./pages/Booking";
import Blogs from "./pages/Blogs";
// import { render } from "react-testing-library";

const Home = Loadable({
  loader: () => import("./pages/Home"),
  loading: Loading,
});

const Info = Loadable({
  loader: () => import("./pages/Info"),
  loading: Loading,
});

const Intro = Loadable({
  loader: () => import("./pages/Intro"),
  loading: Loading,
});

const Diary = Loadable({
  loader: () => import("./pages/Diary"),
  loading: Loading,
});

const Pricing = Loadable({
  loader: () => import("./pages/Pricing"),
  loading: Loading,
});

const Booking = Loadable({
  loader: () => import("./pages/Booking"),
  loading: Loading,
});

const Charity = Loadable({
  loader: () => import("./pages/Charity"),
  loading: Loading,
});

// const Blogs = Loadable({
//   loader: () => import("./pages/Blogs"),
//   loading: Loading,
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ScrollTop>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/" element={<Home />}></Route>
            <Route path="info" element={<Info />}></Route>
            <Route path="/intro" element={<Intro />}></Route>
            <Route path="/diary" element={<Diary />}></Route>
            <Route path="/pricing" element={<Pricing />}></Route>
            <Route path="/charity" element={<Charity />}></Route>
            <Route path="/booking" element={<Booking />}></Route>
            <Route path="/blogs" element={<Blogs />}>
              {" "}
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </ScrollTop>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
