import { Link } from "react-router-dom";
import logoWhite from "../static/logo-white.png";

const Footer = () => {
  return (
    <footer className="flex flex-col justify-between bg-[#ACA394] pb-6 pt-10 text-white xl:h-[600px] xl:pt-8">
      <div className="mx-auto flex w-full max-w-[1600px] flex-col justify-between gap-10 px-10 md:px-20 xl:flex-row xl:gap-64">
        <div className="flex flex-1 justify-center">
          {" "}
          <img
            src={logoWhite}
            className="h-auto w-[100px] xl:h-48 xl:w-auto"
            alt="葵芳 中醫 針灸 正骨 拔罐 刮痧 濕疹 Covid-19 視像診症 遙距診症 高低膊 脊柱側彎 徐宇聖 鄧敏賢 聖賢堂 logo HK "
          ></img>
        </div>
        <div className="flex-1 shrink-0 flex-col items-center justify-center text-sm xl:text-base">
          <p className="text-center">應診服務時間</p>
          <p className="mt-4 text-center">星期一至日: 10:00 - 19:00</p>
          <p className="mt-4 text-center">午休: 14:00 - 15:00</p>
          <p className="mt-4 text-center">公眾假期: 將另行通知</p>
        </div>
        <div className="flex-1 flex-col justify-center text-sm xl:text-base">
          <p className="text-center" style={{ borderBottom: "dashed" }}>
            {" "}
            聯絡資料
          </p>
          <p style={{ textAlign: "center" }} className="pt-2">
            <b>葵芳</b>{" "}
          </p>
          <div className="flex justify-center gap-4">
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_208_825)">
                <path
                  d="M12 5.99999C12 8.73124 8.34376 13.5937 6.74063 15.6C6.35625 16.0781 5.64375 16.0781 5.25938 15.6C3.62813 13.5937 0 8.73124 0 5.99999C0 2.68625 2.68625 0 6 0C9.31251 0 12 2.68625 12 5.99999Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_208_825">
                  <rect width="12" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <a
              href="https://www.google.com/maps?q=%E8%91%B5%E6%B6%8C%E8%88%88%E8%8A%B3%E8%B7%AF223%E8%99%9F%E6%96%B0%E9%83%BD%E6%9C%83%E5%BB%A3%E5%A0%B4%E7%AC%AC%E4%B8%80%E5%BA%A7%E5%BF%A0%E9%86%AB%E8%A8%BA%E6%89%80+%E8%81%96%E8%B3%A2%E5%A0%8221%E6%A8%932113-2114%E5%AE%A4&ftid=0x3403f9238a9e6265:0x8e3237be536203e9&hl=zh-HK&gl=hk&entry=gps&g_ep=CAISBjYuNTguMRgAINeCA0ICSEs%3D&g_st=iw"
              role="button"
            >
              新界葵芳興芳路223號新都會廣場一期21樓
              <p>2113-2114室</p>
            </a>
          </div>
          <div className="mt-6 flex w-[100%] justify-center gap-4 lg:justify-start">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.6582 11.4267L10.9482 8.96266C10.8201 8.84623 10.6518 8.78414 10.4787 8.78948C10.3057 8.79483 10.1415 8.86721 10.0209 8.99133L8.42552 10.632C8.04152 10.5587 7.26952 10.318 6.47485 9.52533C5.68019 8.73 5.43952 7.956 5.36819 7.57466L7.00752 5.97866C7.1318 5.85808 7.20428 5.69388 7.20963 5.5208C7.21498 5.34772 7.15278 5.17936 7.03619 5.05133L4.57285 2.342C4.45622 2.21357 4.29411 2.13567 4.12096 2.12484C3.94781 2.11401 3.77725 2.1711 3.64552 2.284L2.19885 3.52466C2.0836 3.64034 2.0148 3.7943 2.00552 3.95733C1.99552 4.124 1.80485 8.072 4.86619 11.1347C7.53685 13.8047 10.8822 14 11.8035 14C11.9382 14 12.0209 13.996 12.0429 13.9947C12.2059 13.9855 12.3597 13.9164 12.4749 13.8007L13.7149 12.3533C13.8282 12.222 13.8857 12.0516 13.8751 11.8785C13.8645 11.7053 13.7867 11.5432 13.6582 11.4267Z"
                fill="white"
              />
            </svg>
            <div className=" ">
              <p>23332025, 64491632 (10:00-19:00)</p>
              <p className="mt-2 "></p>
            </div>
          </div>
          <p className="mt-2 flex justify-center ">
            <button
              className=""
              onClick={(x) => {
                window.open("https://wa.me/85264491632");
              }}
            >
              <svg
                width="34"
                height="34"
                viewBox="0 0 32 32"
                fill="green"
                xmlns="http://www.w3.org/2000/svg"
                style={{ backgroundColor: "#4dc247", borderRadius: "50%" }}
              >
                <path
                  d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                  fill="white"
                  fillRule="evenodd"
                />
              </svg>
            </button>

            <button
              className="ml-4"
              onClick={(x) => {
                window.open("https://signal.me/#p/+85264491632");
              }}
            >
              <svg
                width="34"
                height="34"
                viewBox="0 0 122.88 122.31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.75,0H95.13a27.83,27.83,0,0,1,27.75,27.75V94.57a27.83,27.83,0,0,1-27.75,27.74H27.75A27.83,27.83,0,0,1,0,94.57V27.75A27.83,27.83,0,0,1,27.75,0Z"
                  fill="#4c83f2"
                  fillRule="evenodd"
                />
                <path
                  d="M61.44,25.39A35.76,35.76,0,0,0,31.18,80.18L27.74,94.86l14.67-3.44a35.77,35.77,0,1,0,19-66ZM41,95.47,35.1,96.85l.94,4,4.35-1a43.36,43.36,0,0,0,10.46,4l1-4A40,40,0,0,1,41,95.45l0,0ZM21.76,86.53l4,.93,1.37-5.91a39.6,39.6,0,0,1-4.43-10.82l-4,1a44.23,44.23,0,0,0,4.06,10.46l-1,4.35Zm9.68,11.15-8.52,2,2-8.52-4-.93-2,8.51a4.12,4.12,0,0,0,3.08,5,4,4,0,0,0,1.88,0l8.52-2-.94-4.06Zm24-76a40.56,40.56,0,0,1,12,0L68,17.63a44.25,44.25,0,0,0-13.2,0l.63,4.07ZM99.14,38.4l-3.53,2.12a39.89,39.89,0,0,1,4.57,11l4-1a43.75,43.75,0,0,0-5-12.18Zm-69.81-.91A40.29,40.29,0,0,1,37.78,29l-2.47-3.32A43.62,43.62,0,0,0,26,35l3.32,2.47ZM85.1,29a40.11,40.11,0,0,1,8.46,8.45L96.88,35a43.62,43.62,0,0,0-9.3-9.3L85.1,29Zm8.46,55.78a40.11,40.11,0,0,1-8.46,8.45l2.45,3.32a44,44,0,0,0,9.33-9.3l-3.32-2.47ZM67.42,100.6a39.89,39.89,0,0,1-12,0l-.62,4.09a44.18,44.18,0,0,0,13.19,0l-.62-4.09Zm36.76-28.88-4-1A40,40,0,0,1,95.6,81.8l3.53,2.12a43.72,43.72,0,0,0,5.05-12.2Zm-2.84-10.57a39.93,39.93,0,0,1-.45,6l4.07.62a44.18,44.18,0,0,0,0-13.19l-4.07.62a39.8,39.8,0,0,1,.45,6ZM84.2,98.85l-2.12-3.53a39.89,39.89,0,0,1-11,4.57l1,4a43.75,43.75,0,0,0,12.18-5ZM21.55,61.15a41.15,41.15,0,0,1,.44-6l-4.07-.62a44.18,44.18,0,0,0,0,13.19L22,67.13a41.28,41.28,0,0,1-.44-6Zm2.2-22.75A43.83,43.83,0,0,0,18.7,50.59l4,1a40.08,40.08,0,0,1,4.57-11.06L23.75,38.4ZM72,18.41l-1,4A40.08,40.08,0,0,1,82.08,27l2.13-3.53A44,44,0,0,0,72,18.41Zm-21.13,0,1,4A40.08,40.08,0,0,0,40.8,27l-2.12-3.53a44,44,0,0,1,12.2-5.05Z"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </svg>
            </button>

            <button
              className="ml-4"
              onClick={(x) => {
                window.open("https://t.me/sytchinesemedic");
              }}
            >
              <svg
                style={{ backgroundColor: "white", borderRadius: "50%" }}
                width="34"
                height="34"
                viewBox="0 0 16 16"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"
                  fill="blue"
                  fillRule="evenodd"
                />
              </svg>
            </button>
          </p>

          <p
            className="mt-7 pt-2"
            style={{ textAlign: "center", borderTop: "dashed" }}
          >
            <b>元朗</b>
          </p>
          <div className="flex justify-center gap-4">
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_208_825)">
                <path
                  d="M12 5.99999C12 8.73124 8.34376 13.5937 6.74063 15.6C6.35625 16.0781 5.64375 16.0781 5.25938 15.6C3.62813 13.5937 0 8.73124 0 5.99999C0 2.68625 2.68625 0 6 0C9.31251 0 12 2.68625 12 5.99999Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_208_825">
                  <rect width="12" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <a
              href="https://www.google.com/maps?q=%E5%85%83%E6%9C%97%E9%9D%92%E5%B1%B1%E5%85%AC%E8%B7%AF%EF%BC%8D%E5%85%83%E6%9C%97%E6%AE%B565-67%E8%99%9F%E8%B1%AA%E6%99%AF%E5%95%86%E6%A5%AD%E5%A4%A7%E5%BB%88%E5%BF%A0%E9%86%AB%E8%A8%BA%E6%89%80+%E8%81%96%E8%B3%A2%E5%A0%8220%E6%A8%93&ftid=0x3403f14386153931:0xe72e62f9bc7dd14b&hl=zh-HK&gl=hk&entry=gps&g_ep=CAISBjYuNTguMRgAINeCA0ICSEs%3D&g_st=iw"
              role="button"
            >
              元朗青山公路元朗段六十五號豪景商業大廈
              <p>二十樓全層</p>
            </a>
          </div>
          <div className="mt-6  flex w-[100%] justify-center gap-4 lg:justify-start ">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.6582 11.4267L10.9482 8.96266C10.8201 8.84623 10.6518 8.78414 10.4787 8.78948C10.3057 8.79483 10.1415 8.86721 10.0209 8.99133L8.42552 10.632C8.04152 10.5587 7.26952 10.318 6.47485 9.52533C5.68019 8.73 5.43952 7.956 5.36819 7.57466L7.00752 5.97866C7.1318 5.85808 7.20428 5.69388 7.20963 5.5208C7.21498 5.34772 7.15278 5.17936 7.03619 5.05133L4.57285 2.342C4.45622 2.21357 4.29411 2.13567 4.12096 2.12484C3.94781 2.11401 3.77725 2.1711 3.64552 2.284L2.19885 3.52466C2.0836 3.64034 2.0148 3.7943 2.00552 3.95733C1.99552 4.124 1.80485 8.072 4.86619 11.1347C7.53685 13.8047 10.8822 14 11.8035 14C11.9382 14 12.0209 13.996 12.0429 13.9947C12.2059 13.9855 12.3597 13.9164 12.4749 13.8007L13.7149 12.3533C13.8282 12.222 13.8857 12.0516 13.8751 11.8785C13.8645 11.7053 13.7867 11.5432 13.6582 11.4267Z"
                fill="white"
              />
            </svg>
            <div className=" ">
              <p>24683668, 63811939 (10:00-19:00)</p>
            </div>
          </div>
          <p className="mt-2 flex justify-center ">
            <button
              className=""
              onClick={(x) => {
                window.open("https://wa.me/85263811939");
              }}
            >
              <svg
                width="34"
                height="34"
                viewBox="0 0 32 32"
                fill="green"
                xmlns="http://www.w3.org/2000/svg"
                style={{ backgroundColor: "#4dc247", borderRadius: "50%" }}
              >
                <path
                  d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                  fill="white"
                  fillRule="evenodd"
                />
              </svg>
            </button>

            <button
              className="ml-4"
              onClick={(x) => {
                window.open("https://signal.me/#p/+85263811939");
              }}
            >
              <svg
                width="34"
                height="34"
                viewBox="0 0 122.88 122.31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.75,0H95.13a27.83,27.83,0,0,1,27.75,27.75V94.57a27.83,27.83,0,0,1-27.75,27.74H27.75A27.83,27.83,0,0,1,0,94.57V27.75A27.83,27.83,0,0,1,27.75,0Z"
                  fill="#4c83f2"
                  fillRule="evenodd"
                />
                <path
                  d="M61.44,25.39A35.76,35.76,0,0,0,31.18,80.18L27.74,94.86l14.67-3.44a35.77,35.77,0,1,0,19-66ZM41,95.47,35.1,96.85l.94,4,4.35-1a43.36,43.36,0,0,0,10.46,4l1-4A40,40,0,0,1,41,95.45l0,0ZM21.76,86.53l4,.93,1.37-5.91a39.6,39.6,0,0,1-4.43-10.82l-4,1a44.23,44.23,0,0,0,4.06,10.46l-1,4.35Zm9.68,11.15-8.52,2,2-8.52-4-.93-2,8.51a4.12,4.12,0,0,0,3.08,5,4,4,0,0,0,1.88,0l8.52-2-.94-4.06Zm24-76a40.56,40.56,0,0,1,12,0L68,17.63a44.25,44.25,0,0,0-13.2,0l.63,4.07ZM99.14,38.4l-3.53,2.12a39.89,39.89,0,0,1,4.57,11l4-1a43.75,43.75,0,0,0-5-12.18Zm-69.81-.91A40.29,40.29,0,0,1,37.78,29l-2.47-3.32A43.62,43.62,0,0,0,26,35l3.32,2.47ZM85.1,29a40.11,40.11,0,0,1,8.46,8.45L96.88,35a43.62,43.62,0,0,0-9.3-9.3L85.1,29Zm8.46,55.78a40.11,40.11,0,0,1-8.46,8.45l2.45,3.32a44,44,0,0,0,9.33-9.3l-3.32-2.47ZM67.42,100.6a39.89,39.89,0,0,1-12,0l-.62,4.09a44.18,44.18,0,0,0,13.19,0l-.62-4.09Zm36.76-28.88-4-1A40,40,0,0,1,95.6,81.8l3.53,2.12a43.72,43.72,0,0,0,5.05-12.2Zm-2.84-10.57a39.93,39.93,0,0,1-.45,6l4.07.62a44.18,44.18,0,0,0,0-13.19l-4.07.62a39.8,39.8,0,0,1,.45,6ZM84.2,98.85l-2.12-3.53a39.89,39.89,0,0,1-11,4.57l1,4a43.75,43.75,0,0,0,12.18-5ZM21.55,61.15a41.15,41.15,0,0,1,.44-6l-4.07-.62a44.18,44.18,0,0,0,0,13.19L22,67.13a41.28,41.28,0,0,1-.44-6Zm2.2-22.75A43.83,43.83,0,0,0,18.7,50.59l4,1a40.08,40.08,0,0,1,4.57-11.06L23.75,38.4ZM72,18.41l-1,4A40.08,40.08,0,0,1,82.08,27l2.13-3.53A44,44,0,0,0,72,18.41Zm-21.13,0,1,4A40.08,40.08,0,0,0,40.8,27l-2.12-3.53a44,44,0,0,1,12.2-5.05Z"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </svg>
            </button>

            <button
              className="ml-4"
              onClick={(x) => {
                window.open("https://t.me/sytcmyl");
              }}
            >
              <svg
                style={{ backgroundColor: "white", borderRadius: "50%" }}
                width="34"
                height="34"
                viewBox="0 0 16 16"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"
                  fill="blue"
                  fillRule="evenodd"
                />
              </svg>
            </button>
          </p>
        </div>
      </div>
      <div className="mx-auto mt-20 flex w-full max-w-[1600px] justify-center pb-5 md:justify-end md:px-20 md:pb-0 xl:mt-0">
        <div className="right-0 flex flex-col gap-8 text-xs md:flex-row md:text-sm">
          <Link to="/info" className="cursor-pointer hover:underline">
            關於我們
          </Link>
          <Link to="/intro" className="cursor-pointer hover:underline">
            醫師介紹
          </Link>
          <Link to="/diary" className="cursor-pointer hover:underline">
            聖賢隨記
          </Link>
          <Link to="/pricing" className="cursor-pointer hover:underline">
            服務及收費
          </Link>
          <Link to="/blogs" className="cursor-pointer hover:underline">
            聖賢中醫錦囊
          </Link>
          <Link to="/charity" className="cursor-pointer hover:underline">
            慈善公益
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
