import logo from "../static/Logo.png";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { useEffect, useState, useRef, useContext, FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useLocation } from "react-router-dom";
import Example from "./popup";
import { NavContext } from "../context/CartProvider";
import { Icon } from '@iconify/react';
import useWindowDimensions from "../utils/useWindowDimensions";
import 慈善公益 from '../static/menuIcon/慈善公益.png';
import 聖賢中醫錦囊 from '../static/menuIcon/聖賢中醫錦囊.png';
import 服務及收費 from '../static/menuIcon/服務及收費.png';
import 聖賢隨記 from '../static/menuIcon/聖賢隨記.png';
import 醫師介紹 from '../static/menuIcon/醫師介紹.png';
import 關於我們 from '../static/menuIcon/關於我們.png'
interface NavProps{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const Nav:FC<NavProps> =({isOpen,setIsOpen})  => {
  const [tl, setTl] = useState(() => gsap.timeline({ pause: true }));
  const load = useSelector((state: RootState) => state.load.load);
  const { height } = useWindowDimensions();
  const location = useLocation();

  useEffect(() => {
    const array = gsap.utils.toArray(".option-con");
    array.forEach((item: any, index: number) => {
      tl.from(item, { opacity: 0, delay: 0.1 * index, duration: 0.8 }, 0);
    });
    tl.from(".logo", { opacity: 0, x: -25, duration: 0.8 }, 0);
  }, []);

  useEffect(() => {
    load ? tl.play() : tl.pause();
  }, [load, tl]);

  if(isOpen){
    return (
      <div className="block w-full z-[1000000] bg-[#F5E5D6] pb-8" style={{height:height}}>
        <div className=" flex flex-col gap-5 ">
        <nav className="mx-auto flex w-full max-w-10xl justify-between px-6 py-8 pb-0 md:px-20">
          <Link to="/" className="h-fit w-fit shrink-0">
            <img
              src={logo}
              alt="葵芳 中醫 針灸 正骨 拔罐 刮痧 濕疹 Covid-19 視像診症 遙距診症 高低膊 脊柱側彎 徐宇聖 鄧敏賢 聖賢堂 logo HK "
              className="logo h-16 w-auto md:h-full"
            ></img>
          </Link>
          <ul className="flex gap-6 hidden lg:flex ">

          </ul>
          <ul className="flex gap-6 flex lg:hidden">
            <div onClick={()=>setIsOpen(!isOpen)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3 18v-2h18v2H3Zm0-5v-2h18v2H3Zm0-5V6h18v2H3Z"/></svg>
            </div>
          </ul>
        </nav>

          <Link
            onClick={()=>setIsOpen(!isOpen)}
            to="/info"
            className="px-4 py-4 md:px-20 border-2 border-[#ACA394] flex align-items-center "
          >
            <div className="pr-8 pl-3.5" >
              <img src={關於我們} className="h-6 w-6" />
            </div>
            關於我們
          </Link>
          <Link
            onClick={()=>setIsOpen(!isOpen)}
            to="intro"
            className="px-4 py-4 md:px-20 border-2 border-[#ACA394] flex align-items-center"
          >
            <div className="pr-8 pl-3.5" >
              <img src={醫師介紹} className="h-6 w-6" />
            </div>
            醫師介紹
          </Link>
          <Link
            onClick={()=>setIsOpen(!isOpen)}
            to="/diary"
            className="px-4 py-4 md:px-20 border-2 border-[#ACA394] flex align-items-center"
          >
            <div className="pr-8 pl-3.5" >
              <img src={聖賢隨記} className="h-6 w-6" />
            </div>
            聖賢隨記
          </Link>
          <Link
            onClick={()=>setIsOpen(!isOpen)}
            to="/pricing"
            className="px-4 py-4 md:px-20 border-2 border-[#ACA394] flex align-items-center"
          >
            <div className="pr-8 pl-3.5" >
              <img src={服務及收費} className="h-6 w-6" />
            </div>
            服務及收費
          </Link>
          <Link
            onClick={()=>setIsOpen(!isOpen)}
            to="/blogs"
            className="px-4 py-4 md:px-20 border-2 border-[#ACA394] flex align-items-center"
          >
            <div className="pr-8 pl-3.5">
              {/*<Icon icon="arcticons:vivo-tips" fontSize={24} style={{height:"100%"}}/>*/}
              <img src={聖賢中醫錦囊} className="h-6 w-6" />
            </div>
            聖賢中醫錦囊
          </Link>
          <Link
            onClick={()=>setIsOpen(!isOpen)}
            to="/charity"
            className="px-4 py-4 md:px-20 border-2 border-[#ACA394] flex align-items-center"
          >
            <div className="pr-8 pl-3.5" >
              {/*<Icon icon="mdi:charity-outline" color="gray"  fontSize={24} style={{height:"100%"}}/>*/}
              <img src={慈善公益} className="h-6 w-6"/>
            </div>
            慈善公益
          </Link>
        </div>
      </div>
    )
  }
  return (
    <nav className="mx-auto flex w-full max-w-10xl justify-between px-6 py-8 pb-0 md:px-20">
      <Link to="/" className="h-fit w-fit shrink-0">
        <img
          src={logo}
          alt="葵芳 中醫 針灸 正骨 拔罐 刮痧 濕疹 Covid-19 視像診症 遙距診症 高低膊 脊柱側彎 徐宇聖 鄧敏賢 聖賢堂 logo HK "
          className="logo h-16 w-auto md:h-full"
        ></img>
      </Link>
      <ul className="flex gap-6 hidden lg:flex ">
        <Link
          to="/info"
          className="option-con h-fit cursor-pointer border-r border-r-[#D9C9BA] pr-3 text-sm text-[#2A2016] md:text-base"
        >
          關於我們
        </Link>

        <Link
          to="intro"
          className="option-con h-fit cursor-pointer border-r border-r-[#D9C9BA] pr-3 text-sm text-[#2A2016] md:text-base"
        >
          醫師介紹
        </Link>
        <Link
          to="/diary"
          className="option-con h-fit cursor-pointer border-r border-r-[#D9C9BA] pr-3 text-sm text-[#2A2016] md:text-base"
        >
          聖賢隨記
        </Link>
        <Link
          to="/pricing"
          className="option-con h-fit cursor-pointer border-r border-r-[#D9C9BA] pr-3 text-sm text-[#2A2016] md:text-base"
        >
          服務及收費
        </Link>
        <Link
          to="/blogs"
          className="option-con h-fit cursor-pointer border-r border-r-[#D9C9BA] pr-3 text-sm text-[#2A2016] md:text-base"
        >
          聖賢中醫錦囊
        </Link>
        <Link
          to="/charity"
          className="option-con h-fit cursor-pointer pr-0 text-sm text-[#2A2016] md:text-base"
        >
          慈善公益
        </Link>
        <Example></Example>
      </ul>
      <ul className="flex gap-6 flex lg:hidden">
        <Example></Example>
        <div onClick={()=>setIsOpen(!isOpen)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3 18v-2h18v2H3Zm0-5v-2h18v2H3Zm0-5V6h18v2H3Z"/></svg>
        </div>
      </ul>
    </nav>
  );
};

export default Nav;
