import { createContext, FC, ReactNode, useMemo, useState } from "react";

interface InavContext {
  isOpen: boolean;
  setIsOpen: (React.Dispatch<React.SetStateAction<boolean>>)
}
export const NavContext = createContext<InavContext>({
  isOpen:false,
  setIsOpen:()=>{}
});

interface Props {
  [propName: string]: unknown;
  children: ReactNode;
}
const NavContextProvider: FC<Props> = ({ children }) => {
  const [isOpen,setIsOpen ] = useState(false)

  const value = useMemo(
    () => ({ isOpen, setIsOpen }),
    [isOpen, setIsOpen],
  );
  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
}

export const NavProvider: FC<Props> = ({ children }) => {
  return (
    <>
        <NavContextProvider>{children}</NavContextProvider>
    </>
  );
};
