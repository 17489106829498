import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./App.scss";
import { Outlet } from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import { useLocation } from "react-router-dom";
import { RootState } from "./store";
import { useDispatch, useSelector } from "react-redux";
import { loadActions } from "./store/loadSlice";
import { NavContext, NavProvider } from "./context/CartProvider";

function App() {
  const location = useLocation();
  const ref: any = useRef(null);
  const load = useSelector((state: RootState) => state.load.load);
  const [isOpen,setIsOpen ] = useState(false)
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(loadActions.loading());
    Promise.all(
      Array.from(document.images)
        .filter((img) => !img.complete)
        .map(
          (img) =>
            new Promise((resolve) => {
              img.onload = img.onerror = resolve;
            })
        )
    ).then(() => {
      setTimeout(() => {
        dispatch(loadActions.loaded());
      }, 1000);
    });
  }, [location.pathname]);


  return (
    <>
      <Loading loaded={load} />
      <NavProvider>
        <div ref={ref} className="min-h-screen bg-[#F5E5D6]">
          <Nav  isOpen={isOpen} setIsOpen={setIsOpen}/>
          {!isOpen&&<>
            <Outlet />
            <Footer />
          </>}

        </div>
      </NavProvider>
    </>
  );
}

export default App;
