import { createSlice } from "@reduxjs/toolkit";

const loadSlice = createSlice({
  name: "load",
  initialState: { load: false },
  reducers: {
    loaded(state:{load:boolean}) {
      state.load = true;
    },
    loading(state:{load:boolean}) {
      state.load = false;
    },
  },
});

export const loadActions = loadSlice.actions;

export default loadSlice;
