import logoWhite from "../static/logo-white.png";

const Loading = (props: any) => {
  return (
    <div
      data-load={props.loaded}
      className="wrapper fixed top-0 left-0 z-[300] grid h-full w-full place-items-center bg-[#ACA394]"
    >
        <img alt="葵芳 中醫 針灸 正骨 拔罐 刮痧 濕疹 Covid-19 視像診症 遙距診症 高低膊 脊柱側彎 徐宇聖 鄧敏賢 聖賢堂 logo HK " src={logoWhite} className="w-[10%] min-w-[150px] md:min-w-[200px] h-auto"></img>
    </div>
  );
};
export default Loading;
